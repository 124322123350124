import api from "../api"
import { updateErrorMessage } from "../commonSlice"
import { updateAccessToken } from "../userAuthSlice"

const adminUser = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const loginResponse = await fetchWithBQ({
            url: `/api/getadmin?address=${_arg.address}`,
            method: "GET"
          })
          _queryApi.dispatch(updateAccessToken(loginResponse?.data?.token))
          if (loginResponse.error) {
            _queryApi.dispatch(
              updateErrorMessage({
                message: loginResponse.error?.data?.msg || "error",
                type: "error"
              })
            )
            throw loginResponse.error
          }

          return loginResponse
        } catch (error) {
          return error.status
            ? { error }
            : { error: { status: 400, data: error } }
        }
      }
    }),

    getAdmins: builder.query({
      query: () => ({
        url: `/api/getadmins`
      }),
      providesTags: ["Admins"]
    }),
    deleteAdmin: builder.mutation({
      query: ({ id }) => ({
        url: `/api/deleteadmin`,
        method: "DELETE",
        body: {
          id
        }
      }),
      invalidatesTags: ["Admins"]
    }),
    addAdmin: builder.mutation({
      query: ({ address, name }) => ({
        url: `/api/addadmin`,
        method: "POST",
        body: {
          address,
          name
        }
      }),
      invalidatesTags: ["Admins"]
    })
  }),
  overrideExisting: false
})

export const {
  useLoginMutation,
  useGetAdminsQuery,
  useDeleteAdminMutation,
  useAddAdminMutation
} = adminUser
