import api from "../api";
import { updateErrorMessage } from "../commonSlice";
import qs from "query-string";
const EventApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addEvent: builder.mutation({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const eventResponse = await fetchWithBQ({
            url: `/api/addevent`,
            method: "POST",
            body: _arg,
          });

          if (eventResponse.error) {
            _queryApi.dispatch(
              updateErrorMessage({
                message: eventResponse.error?.data?.msg || "error",
                type: "error",
              })
            );
            throw eventResponse.error;
          }
          _queryApi.dispatch(
            updateErrorMessage({
              message: eventResponse?.data?.msg || "Success",
              type: "success",
            })
          );

          return eventResponse?.data;
        } catch (error) {
          return error.status
            ? { error }
            : { error: { status: 400, data: error } };
        }
      },
      invalidatesTags: ["Event"],
    }),

    updateEvent: builder.mutation({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const updateEventRes = await fetchWithBQ({
            url: `/api/updateevent`,
            method: "POST",
            body: _arg,
          });
          //   console.log("updateEventRes", updateEventRes);
          if (updateEventRes.error) {
            _queryApi.dispatch(
              updateErrorMessage({
                message: updateEventRes.error?.data?.msg || "error",
                type: "error",
              })
            );
            throw updateEventRes.error;
          }
          _queryApi.dispatch(
            updateErrorMessage({
              message: updateEventRes?.data?.msg || "Success",
              type: "success",
            })
          );

          return updateEventRes?.data;
        } catch (error) {
          return error.status
            ? { error }
            : { error: { status: 400, data: error } };
        }
      },
      invalidatesTags: ["Event"],
    }),

    getEvents: builder.query({
      query: (query) => {
        const q = qs.stringify(query);
        return {
          url: `/api/getevents?${q}`,
        };
      },
      providesTags: ["Event"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetEventsQuery,
  useUpdateEventMutation,
  useAddEventMutation,
} = EventApi;
