import { Box, Stack, Typography } from "@mui/material"
import React from "react"
import Moment from "react-moment"

const EventCard = ({ event }) => {
  return (
    <Stack
      direction="column"
      alignItems="center"
      height={"100%"}
      sx={{ cursor: "pointer", position: "relative" }}>
      <Box
        sx={{
          width: "100%",
          height: "10px",
          backgroundColor: event?.isActive ? "green" : "red"
        }}
      />
      <Typography
        variant="h5"
        sx={{
          position: "absolute",
          top: 10,
          left: 10,
          textShadow: "-1px -1px 2px black, 1px 1px 2px black"
        }}>
        {event?.token_id}
      </Typography>
      <Typography
        variant="h6"
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
          textShadow: "-1px -1px 2px black, 1px 1px 2px black"
        }}>
        {event?.category?.name}
      </Typography>

      <Box sx={{ m: 1 }}>
        <img
          src={event?.metadata?.image}
          width={"100%"}
          height="auto"
          alt="img"
        />
      </Box>
      <Box sx={{ height: "100%" }}></Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="body2" sx={{ display: "block", fontWeight: 600 }}>
          {event?.metadata?.name}
        </Typography>
        <Typography
          variant="caption"
          sx={{ display: "block", fontWeight: 600 }}>
          {event?.metadata?.description}
        </Typography>

        {/* <Typography variant="caption">{event?.metadata?.description}</Typography> */}

        <Typography variant="body1" fontWeight={600}>
          <Moment format="YYYY/MM/DD">{event?.dateTime}</Moment>
        </Typography>
      </Box>
    </Stack>
  )
}

export default EventCard
