import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material"
import React from "react"
import styled from "@emotion/styled"
import { useFormik } from "formik"
import * as YUP from "yup"
import { useAddEventMutation } from "../../services/endpoints/EventApi.api"
import { useCategoriesQuery } from "../../services/endpoints/category.api"
const CategoryField = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: "22px"
  }
})
const AddEvent = ({ onClose }) => {
  const [addEventMutate, { isLoading }] = useAddEventMutation()
  const {
    data: categories
    // isLoading: categoriesLoading,
    // isFetching: categoriesFetching
  } = useCategoriesQuery()
  const formik = useFormik({
    initialValues: {
      token_id: "",
      token_address: "0x70d4a35810616Bc0352D82602C0be21d4FDB566e",
      category: "",
      dateTime: ""
    },
    validationSchema: YUP.object().shape({
      token_address: YUP.string().required("Token Address is required"),
      category: YUP.string().required("Category is required"),
      token_id: YUP.string().required("token Id is required"),
      dateTime: YUP.date().required("Date Time is required")
    }),
    onSubmit: async (values) => {
      await addEventMutate(values)
      formik.resetForm()
      onClose()
    }
  })
  return (
    <Stack rowGap={1} columnGap={1} height={"100%"}>
      <Stack
        sx={{ flexDirection: { md: "row", xs: "column" } }}
        rowGap={1}
        columnGap={1}>
        <CategoryField
          id="add-new-event-token_id-input"
          value={formik.values.token_id}
          onChange={formik.handleChange}
          name="token_id"
          label="Token ID"
          error={formik.touched?.token_id && Boolean(formik.errors?.token_id)}
          helperText={formik.touched.token_id ? formik.errors.token_id : ""}
          fullWidth
        />

        <CategoryField
          id="add-new-token-address-nft-input"
          value={formik.values.dateTime}
          onChange={formik.handleChange}
          name="dateTime"
          InputLabelProps={{ shrink: true }}
          type="date"
          label="Event Date"
          error={formik.touched?.dateTime && Boolean(formik.errors?.dateTime)}
          helperText={formik.touched.dateTime ? formik.errors.dateTime : ""}
          fullWidth
        />
      </Stack>
      <Stack py={0.5}>
        <FormControl fullWidth>
          <InputLabel id="category-select-label">Category</InputLabel>
          <Select
            labelId="category-select-label"
            label="Category"
            name="category"
            value={formik?.values?.category}
            onChange={formik.handleChange}
            error={formik.touched?.category && Boolean(formik.errors?.category)}
            sx={{
              borderRadius: "22px"
            }}>
            {categories?.categories?.map((category, index) => (
              <MenuItem key={index} value={category?._id}>
                {category?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormHelperText
          sx={{ ml: 2 }}
          error={formik.touched?.category && Boolean(formik.errors?.category)}>
          {formik.touched.category ? formik.errors.category : ""}
        </FormHelperText>
      </Stack>
      <CategoryField
        id="add-new-event-token_address-input"
        value={formik.values.token_address}
        onChange={formik.handleChange}
        name="token_address"
        label="Token Address"
        error={
          formik.touched?.token_address && Boolean(formik.errors?.token_address)
        }
        helperText={
          formik.touched.token_address ? formik.errors.token_address : ""
        }
        fullWidth
      />

      <Button onClick={formik.handleSubmit} disabled={isLoading}>
        {" "}
        {isLoading ? "Loading..." : "Add"}
      </Button>
    </Stack>
  )
}

export default AddEvent
