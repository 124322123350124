import { Route, Routes } from "react-router-dom"
import NotFound from "./Screen/NotFound"
import Admin from "./Screen/Admin/Admin"
import Login from "./Screen/Authentication/Login"
import SnackBar from "./Components/SnackBar"
import PrivateRoute from "./Screen/Authentication/PrivateRoute"
function App() {
  return (
    <>
      <SnackBar />
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Login />} />
        {/* <Route path="/" element={<Home />} /> */}

        <Route
          path="/admin/*"
          element={
            <PrivateRoute>
              <Admin />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  )
}

export default App
