import { createSlice } from "@reduxjs/toolkit";

const initialState = { token: "" };

const userAuthSlice = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    updateAccessToken(state, action) {
      state.token = action.payload;
      if (action.payload) {
        localStorage.setItem("token", action.payload);
      }
    },
  },
});

export const { updateAccessToken } = userAuthSlice.actions;
export const userAuthReducer = userAuthSlice.reducer;
export default userAuthSlice;
