import {
  Box,
  Button,
  Container,
  FormControl,
  OutlinedInput,
  Typography
} from "@mui/material"
import React, { useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
// import Web3 from "web3";

import Layout from "../../../Components/Layout/Layout"

import { useGetEventParticipentsQuery } from "../../../services/endpoints/participants"
const columns = [
  { id: "eventId", label: "Event Id", minWidth: 220 },
  { id: "userName", label: "User", minWidth: 100, maxWidth: 100 },
  { id: "userId", label: "User Id", minWidth: 220 },
  { id: "createdAt", label: "Date", minWidth: 100, maxWidth: 200 }
  // {
  //   id: "UserId",
  //   label: "Delete",
  //   minWidth: 100,
  //   maxWidth: 100,
  //   align: "right"
  // }
]

const Participants = () => {
  const [search, setSearch] = useState()
  const [searching, setSearching] = useState()
  const {
    data: participants,
    isLoading: isAdminsLoading,
    isFetching: isAdminsFetching
  } = useGetEventParticipentsQuery({ eventId: searching })
  const handleSubmit = (e) => {
    e.preventDefault()
    setSearching(search)
  }
  return (
    <Layout isLoading={isAdminsLoading || isAdminsFetching}>
      {/* {loading && <Loader />} */}

      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            m: { sm: 0, md: 1, lg: 3 }
          }}>
          <Typography
            variant="h4"
            color={(theme) => theme.palette.getContrastText}
            component={"h4"}
            fontWeight="bold">
            PARTICIPANTS
          </Typography>
        </Box>
        <hr />
        <Box
          sx={{
            display: "flex",
            marginBottom: "20px",
            gap: "10px",
            justifyContent: "space-between"
          }}>
          <Box sx={{ display: "flex", gap: "6px", flexGrow: 1 }}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              sx={{
                display: "flex",
                gap: "10px",
                rowGap: "30px",
                flexWrap: "wrap",
                width: "100%"
              }}>
              <FormControl
                sx={{
                  width: "100%",
                  flex: "3 1 0",

                  minWidth: "25ch",
                  height: "37px",
                  "& .MuiOutlinedInput-root": { height: "37px" }
                }}>
                <OutlinedInput
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  placeholder="Search By Event Id"
                />
              </FormControl>

              <FormControl
                sx={{
                  width: "100%",
                  flex: "1 1 0",
                  minWidth: "25ch",
                  height: "37px",
                  "& .MuiOutlinedInput-root": { height: "37px" }
                }}>
                <Button type="submit">Search</Button>
              </FormControl>
            </Box>
          </Box>
        </Box>
        {/* <Box sx={{ display: "flex", justifyContent: "center", m: 3 }}>
              <Button></Button>
            </Box> */}
        <Paper>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650, overflow: "auto" }}
              aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {participants?.participants?.length ? (
                  participants?.participants.map((participan) => (
                    <TableRow
                      key={participan._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 }
                      }}>
                      <TableCell component="th" scope="row">
                        {participan.eventId}
                      </TableCell>
                      <TableCell>{participan.userName}</TableCell>
                      <TableCell>{participan.userId}</TableCell>
                      <TableCell>{participan.createdAt}</TableCell>
                      {/* <TableCell align="right">
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDelete(admin._id)}>
                          <MdDelete color="#fff" />
                        </IconButton>
                      </TableCell> */}
                    </TableRow>
                  ))
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    </Layout>
  )
}

export default Participants
