import api from "../api"
import qs from "query-string"
const participantApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEventParticipents: builder.query({
      query: (query) => {
        const q = qs.stringify(query)
        return { url: `/api/eventparticipants?${q}` }
      }
    }),

    addParticipant: builder.mutation({
      query: (args) => ({
        url: `/api/participate`,
        method: "POST",
        body: args
      })
    })
  }),
  overrideExisting: false
})

export const { useAddParticipantMutation, useGetEventParticipentsQuery } =
  participantApi
