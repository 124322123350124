import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";


export const providerOptions = {
    coinbasewallet: {
      package: CoinbaseWalletSDK,
      options: {
        appName: "Web3Modal demo",
        infuraId: {
          1: "https://mainnet.infura.io/v3/8a4eeb5f85b2433d804f58ef1a5a654c",
          5: "https://goerli.infura.io/v3/8a4eeb5f85b2433d804f58ef1a5a654c"
        }
      }
    },
    walletconnect: {
      package: WalletConnect, // required
      // package: WalletConnect, // required
      options: {
        infuraId: "8a4eeb5f85b2433d804f58ef1a5a654c" // required
      }
    },
  };