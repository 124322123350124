import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  // Button,
  IconButton,
  Stack,
  Typography
} from "@mui/material"
import React from "react"
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import EditIcon from "@mui/icons-material/Edit"
const CategoryCard = ({
  togleEditModal,
  // togleSubCategory,
  categories,
  onEdit
  // onSubEdit
}) => {
  // const [expanded, setExpanded] = useState(false)

  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false)
  // }
  return (
    <>
      {categories?.map((category) => (
        <Stack
          p={2}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          mb={2}
          sx={{ background: "rgba(0,0,0,0.2)" }}>
          <Typography>{category.name}</Typography>
          {category.name !== "Uncategorized" && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                togleEditModal()
                onEdit(category)
              }}>
              <EditIcon />
            </IconButton>
          )}
        </Stack>
      ))}
      {/* {categories?.map((category) => (
        <Accordion
          expanded={expanded === category._id}
          onChange={handleChange(category._id)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id={`${category._id}-header`}>
            <Typography sx={{ flexGrow: 1 }}>{category.name}</Typography>
            {category.name !== "Uncategorized" && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  togleEditModal()
                  onEdit(category)
                }}>
                <EditIcon />
              </IconButton>
            )}
          </AccordionSummary>
          <AccordionDetails>
            {category?.subCategory?.length ? (
              category?.subCategory.map((subCat) => (
                <Stack direction="row" alignItems={"center"} spacing={2} pl={3}>
                  <Typography sx={subCat.name === "Uncategorized" && { py: 1 }}>
                    {subCat.name}
                  </Typography>

                  {subCat.name !== "Uncategorized" && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation()
                        togleSubCategory()
                        onSubEdit(subCat)
                      }}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  )}
                </Stack>
              ))
            ) : (
              <></>
            )}
          </AccordionDetails>
        </Accordion>
      ))} */}
    </>
  )
}

export default CategoryCard
