import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  Typography
} from "@mui/material"
import React, { useState } from "react"

import Paper from "@mui/material/Paper"
// import Web3 from "web3";

import Layout from "../../../Components/Layout/Layout"
import SimpleDialog from "../../../Components/SimpleDialog"
import AddEvent from "../../../Components/form/AddEvent"
import EventCard from "../../../Components/EventCard"
import {
  useGetEventsQuery,
  useUpdateEventMutation
} from "../../../services/endpoints/EventApi.api"
import EditEvent from "../../../Components/form/EditEvent"
import { useFormik } from "formik"
import * as YUP from "yup"
import { useCategoriesQuery } from "../../../services/endpoints/category.api"

const initialVAlues = {
  title: "",
  token_id: "",
  description: "",
  subTitle: "",
  category: "",
  price: "",
  dateTime: "",
  isActive: false
}
const Dashboard = () => {
  const [isAddNFTModaOpen, setIsAddNFTModaOpen] = useState(false)
  const [editEvent, setEditEvent] = useState()
  const [search, setSearch] = useState("")
  const [categoryFilter, setCategoryFilter] = useState("")
  const [checkTopWeekEvent, setCheckTopWeekEvent] = useState(false)
  const [editNFtModalOpen, setEditNFtModalOpen] = useState()
  const togleAddNFTModal = () => {
    setIsAddNFTModaOpen(!isAddNFTModaOpen)
  }
  const {
    currentData: eventData,
    isLoading: eventLoading,
    isFetching: eventFetching
  } = useGetEventsQuery({
    isadmin: true,
    search,
    category: categoryFilter,
    ...(checkTopWeekEvent && { isTopWeekEvent: checkTopWeekEvent })
  })
  const [updateEventMutate] = useUpdateEventMutation()
  const {
    data: categories
    // isLoading: categoriesLoading,
    // isFetching: categoriesFetching
  } = useCategoriesQuery()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editEvent
      ? {
          ...editEvent,
          dateTime: editEvent.dateTime ?? "",
          category: editEvent?.category?._id
        }
      : initialVAlues,
    validationSchema: YUP.object().shape({
      token_id: YUP.string().required("token Id is required"),
      token_address: YUP.string().required("token Id is required"),
      dateTime: YUP.date().required("Date is required")
    }),
    onSubmit: async (values) => {
      await updateEventMutate(values)
      formik.resetForm()
      setEditNFtModalOpen(false)
      setEditEvent()
    }
  })
  console.log("formik", formik)

  let debounceTimeout = null
  console.log("categories", { categories })

  return (
    <Layout isLoading={eventLoading || eventFetching}>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            m: { sm: 0, md: 1, lg: 3 }
          }}>
          <Typography
            variant="h4"
            color={(theme) => theme.palette.getContrastText}
            component={"h4"}
            fontWeight="bold">
            Admin Dashboard
          </Typography>
        </Box>
        <hr />
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            mt: 2
          }}>
          <Box sx={{ display: "flex", gap: "6px", flexGrow: 1 }}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{ flexGrow: 1 }}
              // onSubmit={handleSubmit}
            >
              <FormControl
                sx={{
                  width: "100%"
                  // height: "37px",
                  // "& .MuiOutlinedInput-root": { height: "37px" }
                }}>
                <OutlinedInput
                  // value={search}
                  onChange={(e) => {
                    if (debounceTimeout) {
                      clearTimeout(debounceTimeout)
                    }
                    debounceTimeout = setTimeout(() => {
                      setSearch(e.target.value)
                    }, 500)
                  }}
                  placeholder="Search NFT by title or tokenid"
                />
              </FormControl>
            </Box>
          </Box>
          <Stack direction={"row"} ml={2} spacing={2}>
            <Button
              color={"primary"}
              variant={"outlined"}
              onClick={togleAddNFTModal}>
              Add Event
            </Button>
            <FormControl sx={{ width: "200px" }}>
              <InputLabel id="category-select-label">Category</InputLabel>
              <Select
                labelId="category-select-label"
                label="Category"
                name="category"
                value={categoryFilter}
                onChange={(e) => setCategoryFilter(e.target.value)}
                sx={{
                  borderRadius: "22px"
                }}>
                <MenuItem value="">None</MenuItem>
                {categories?.categories?.map((category, index) => (
                  <MenuItem key={index} value={category?._id}>
                    {category?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <Typography variant="h6">Top Week Events</Typography>
          <FormControlLabel
            control={
              <Switch
                name="topWeekEvents"
                inputProps={{ "aria-label": "controlled" }}
                checked={checkTopWeekEvent}
                onChange={(e) => {
                  setCheckTopWeekEvent(e.target.checked)
                }}
              />
            }
            labelPlacement="start"
          />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", m: 3 }}></Box>
        <Container sx={{ padding: { xs: 1 } }}>
          <Grid container columnSpacing={1} rowSpacing={1}>
            {eventData?.events?.length ? (
              eventData?.events.map((event) => (
                <Grid item xs={12} md={4}>
                  <Paper
                    sx={{ p: 1, border: "1px solid #e0e0e042", height: "100%" }}
                    onClick={() => {
                      console.log("event", event)
                      setEditEvent(event)
                      setEditNFtModalOpen(true)
                    }}>
                    <EventCard event={event} />
                  </Paper>
                </Grid>
              ))
            ) : (
              <></>
            )}
          </Grid>
        </Container>
      </Container>

      <SimpleDialog
        isOpen={editNFtModalOpen}
        handleClose={() => {
          setEditNFtModalOpen(false)
          setEditEvent()
        }}
        title="Update NFT"
        action={
          <Button
            variant="contained"
            onClick={(e) => {
              formik.handleSubmit(e)
            }}>
            Update
          </Button>
        }>
        <EditEvent formik={formik} categories={categories?.categories} />
      </SimpleDialog>
      <SimpleDialog
        isOpen={isAddNFTModaOpen}
        handleClose={togleAddNFTModal}
        title="Add New Event">
        <AddEvent onClose={togleAddNFTModal} />
      </SimpleDialog>
    </Layout>
  )
}

export default Dashboard
