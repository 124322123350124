// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_tripleSpinnerContainer__BC7Hc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader_tripleSpinner__xd-dG {
  display: block;
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top: 4px solid #572b7d;
  animation: loader_spin__zH0GF 2s linear infinite;
}

.loader_tripleSpinner__xd-dG::before,
.loader_tripleSpinner__xd-dG::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 4px solid transparent;
}

.loader_tripleSpinner__xd-dG::before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-top-color: #9757d7;
  animation: loader_spin__zH0GF 3.5s linear infinite;
}

.loader_tripleSpinner__xd-dG::after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-top-color: #cdb4db;
  animation: loader_spin__zH0GF 1.75s linear infinite;
}

@keyframes loader_spin__zH0GF {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Loader/loader.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,6BAA6B;EAC7B,6BAA6B;EAE7B,gDAAkC;AACpC;;AAEA;;EAEE,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,6BAA6B;AAC/B;;AAEA;EACE,QAAQ;EACR,SAAS;EACT,UAAU;EACV,WAAW;EACX,yBAAyB;EAEzB,kDAAoC;AACtC;;AAEA;EACE,SAAS;EACT,UAAU;EACV,WAAW;EACX,YAAY;EACZ,yBAAyB;EAEzB,mDAAqC;AACvC;;AAEA;EACE;IAEE,uBAAuB;EACzB;EACA;IAEE,yBAAyB;EAC3B;AACF","sourcesContent":[".tripleSpinnerContainer {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.tripleSpinner {\n  display: block;\n  position: relative;\n  width: 100px;\n  height: 100px;\n  border-radius: 50%;\n  border: 4px solid transparent;\n  border-top: 4px solid #572b7d;\n  -webkit-animation: spin 2s linear infinite;\n  animation: spin 2s linear infinite;\n}\n\n.tripleSpinner::before,\n.tripleSpinner::after {\n  content: \"\";\n  position: absolute;\n  border-radius: 50%;\n  border: 4px solid transparent;\n}\n\n.tripleSpinner::before {\n  top: 5px;\n  left: 5px;\n  right: 5px;\n  bottom: 5px;\n  border-top-color: #9757d7;\n  -webkit-animation: spin 3s linear infinite;\n  animation: spin 3.5s linear infinite;\n}\n\n.tripleSpinner::after {\n  top: 15px;\n  left: 15px;\n  right: 15px;\n  bottom: 15px;\n  border-top-color: #cdb4db;\n  -webkit-animation: spin 1.5s linear infinite;\n  animation: spin 1.75s linear infinite;\n}\n\n@keyframes spin {\n  from {\n    -webkit-transform: rotate(0deg);\n    transform: rotate(0deg);\n  }\n  to {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tripleSpinnerContainer": `loader_tripleSpinnerContainer__BC7Hc`,
	"tripleSpinner": `loader_tripleSpinner__xd-dG`,
	"spin": `loader_spin__zH0GF`
};
export default ___CSS_LOADER_EXPORT___;
