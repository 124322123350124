import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography
} from "@mui/material"
import React from "react"
import styled from "@emotion/styled"
import moment from "moment"
const CategoryField = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: "22px"
  }
})
const EditEvent = ({ onClose, formik, categories }) => {
  console.log("formik.values?.dateTime", formik.values?.dateTime)

  return (
    <Stack rowGap={1} columnGap={1} height={"100%"}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        flexWrap={"wrap"}>
        <Typography>ID: {formik.values?._id}</Typography>
        {/* <FormControlLabel
          control={
            <Switch
              name="isActive"
              inputProps={{ "aria-label": "controlled" }}
              checked={formik.values?.isActive}
              onChange={formik.handleChange}
            />
          }
          label="Active"
          labelPlacement="start"
        /> */}
      </Stack>
      <Stack
        direction={"column"}
        // justifyContent={"space-between"}
        flexWrap={"wrap"}>
        <FormControlLabel
          control={
            <Switch
              name="isTopWeekEvent"
              inputProps={{ "aria-label": "controlled" }}
              checked={formik.values?.isTopWeekEvent}
              onChange={formik.handleChange}
            />
          }
          label="Is Top Week Event"
          labelPlacement="start"
        />
        <FormControlLabel
          control={
            <Switch
              name="isOnSell"
              inputProps={{ "aria-label": "controlled" }}
              checked={formik.values?.isOnSell}
              onChange={formik.handleChange}
            />
          }
          label="Is On Sell"
          labelPlacement="start"
        />
      </Stack>

      {/* <Stack
        sx={{ flexDirection: { md: "row", xs: "column" } }}
        rowGap={1}
        columnGap={1}> */}
      <Stack
        sx={{ flexDirection: { md: "row", xs: "column" } }}
        rowGap={1}
        columnGap={1}>
        <CategoryField
          id="add-new-event-token_id-input"
          value={formik.values?.token_id}
          onChange={formik.handleChange}
          name="token_id"
          label="Token ID"
          disabled
          error={formik.touched?.token_id && Boolean(formik.errors?.token_id)}
          helperText={formik.touched.token_id ? formik.errors.token_id : ""}
          fullWidth
        />
        <CategoryField
          id="add-new-token-address-nft-input"
          value={moment(formik.values?.dateTime).format("YYYY-MM-DD") || ""}
          onChange={formik.handleChange}
          name="dateTime"
          InputLabelProps={{ shrink: true }}
          type="date"
          label="Event Date"
          error={formik.touched?.dateTime && Boolean(formik.errors?.dateTime)}
          helperText={formik.touched?.dateTime ? formik.errors.dateTime : ""}
          fullWidth
        />
      </Stack>
      {/* </Stack> */}
      <Stack py={0.5}>
        <FormControl fullWidth>
          <InputLabel id="category-select-label">Category</InputLabel>
          <Select
            labelId="category-select-label"
            label="Category"
            name="category"
            value={formik?.values?.category}
            onChange={formik.handleChange}
            sx={{
              borderRadius: "22px"
            }}>
            {categories?.map((category, index) => (
              <MenuItem key={index} value={category?._id}>
                {category?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <CategoryField
        id="add-new-event-token_address-input"
        value={formik.values?.token_address}
        onChange={formik.handleChange}
        name="token_address"
        label="Token Address"
        error={
          formik.touched?.token_address && Boolean(formik.errors?.token_address)
        }
        disabled
        helperText={
          formik.touched.token_address ? formik.errors.token_address : ""
        }
        fullWidth
      />
    </Stack>
  )
}

export default EditEvent
