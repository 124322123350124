import api from "../api"
import { updateErrorMessage } from "../commonSlice"

const CategoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addUpdateCategory: builder.mutation({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          let categoryResponse
          if (_arg._id) {
            categoryResponse = await fetchWithBQ({
              url: `/api/updatecategory/${_arg._id}`,
              method: "PUT",
              body: { ..._arg, isActive: true }
            })
          } else {
            categoryResponse = await fetchWithBQ({
              url: `/api/addcategory`,
              method: "POST",
              body: { ..._arg, isActive: true }
            })
          }
          //   console.log("categoryResponse", categoryResponse);
          if (categoryResponse.error) {
            _queryApi.dispatch(
              updateErrorMessage({
                message: categoryResponse.error?.data?.msg || "error",
                type: "error"
              })
            )
            throw categoryResponse.error
          }
          _queryApi.dispatch(
            updateErrorMessage({
              message: categoryResponse?.data?.msg || "Success",
              type: "success"
            })
          )

          return categoryResponse?.data
        } catch (error) {
          return error.status
            ? { error }
            : { error: { status: 400, data: error } }
        }
      },
      invalidatesTags: ["Categories"]
    }),
    addUpdateSubCategory: builder.mutation({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const subcategoryResponse = await fetchWithBQ({
            url: `/api/addsubcategory`,
            method: "POST",
            body: _arg
          })
          //   console.log("subcategoryResponse", subcategoryResponse);
          if (subcategoryResponse.error) {
            _queryApi.dispatch(
              updateErrorMessage({
                message: subcategoryResponse.error?.data?.msg || "error",
                type: "error"
              })
            )
            throw subcategoryResponse.error
          }
          _queryApi.dispatch(
            updateErrorMessage({
              message: subcategoryResponse?.data?.msg || "Success",
              type: "success"
            })
          )

          return subcategoryResponse?.data
        } catch (error) {
          return error.status
            ? { error }
            : { error: { status: 400, data: error } }
        }
      },
      invalidatesTags: ["Categories"]
    }),

    categories: builder.query({
      query: () => ({
        url: `/api/categories`
      }),
      providesTags: ["Categories"]
    })
    // deleteAdmin: builder.mutation({
    //   query: ({ id }) => ({
    //     url: `/api/deleteadmin`,
    //     method: "POST",
    //     body: {
    //       id,
    //     },
    //   }),
    //   invalidatesTags: ["Admins"],
    // }),
    // addAdmin: builder.mutation({
    //   query: ({ address, name }) => ({
    //     url: `/api/addadmin`,
    //     method: "POST",
    //     body: {
    //       address,
    //       name,
    //     },
    //   }),
    //   invalidatesTags: ["Admins"],
    // }),
  }),
  overrideExisting: false
})

export const {
  useAddUpdateCategoryMutation,
  useAddUpdateSubCategoryMutation,
  useCategoriesQuery
} = CategoryApi
